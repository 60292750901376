import {
  FilterForm,
  SearchInput,
  AutocompleteInput,
  ReferenceInput,
} from 'react-admin'
import { Stack } from '@mui/material'

const DashboardToolbar = ({ isMobile = false, isAdmin = false }) => {
  const DashboardFilter = [
    <SearchInput
      key="last_name"
      placeholder="Nom"
      source="last_name@ilike"
      variant="outlined"
      sx={{ maxWidth: '200px' }}
      alwaysOn
      resettable
    />,
    <SearchInput
      key="first_name"
      placeholder="Prénom"
      source="first_name@ilike"
      variant="outlined"
      sx={{ maxWidth: '200px' }}
      alwaysOn
      resettable
    />,
  ]

  if ( isAdmin ) {
    DashboardFilter.push(
      <ReferenceInput
        reference="centers"
        source="center_id"
        alwaysOn
      >
        <AutocompleteInput
          label="Centre"
          variant="outlined"
          optionText="name"
          filterToQuery={searchText => ({ 'name@ilike': searchText })}
        />
      </ReferenceInput>,
    )
  }

  return (
    <Stack direction={isMobile ? 'column' : 'row'} style={{ padding: '0 10px' }}>
      <FilterForm filters={DashboardFilter} />
    </Stack>
  )
}

export default DashboardToolbar
