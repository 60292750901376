import { useGetIdentity } from 'react-admin'
import { getRole } from '../common/roles'
import { Container } from '@mui/material'

import DashboardPubeur from './pubeur'
import DashboardRespo from './respo'
import DashboardHippocast from './hippocast'

export default () => {
  const { identity } = useGetIdentity()
  const isHippocast = identity?.role?.grade === getRole('hippocast')
  const isResponsable = identity?.role?.grade >= getRole('responsable')

  // return <Container sx={{ mt: 30, textAlign: 'center' }}><b>Maintenance en cours, merci de patienter...</b><br/>Récupération des tâches...</Container>
  
  return isResponsable
    ? <DashboardRespo />
    : isHippocast
      ? <DashboardHippocast />
      : <DashboardPubeur />
}