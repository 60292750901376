// MonthToolbar.js
import { TopToolbar, FilterButton } from 'react-admin'
import MonthFilter from './MonthFilter'

const MonthToolbar = () => (
  <TopToolbar>
    <MonthFilter />
  </TopToolbar>
)

export default MonthToolbar