import { useListContext } from 'react-admin'
import { Box, Typography } from '@mui/material'

const EmptyMessage = () => {
  const { filterValues } = useListContext()
  const hasRequiredFilters = filterValues.center_id && filterValues['@and']?.['created_at@gte']
  
  if (hasRequiredFilters) {
    return (
      <Box textAlign="center" p={3}>
        <Typography variant="body1">Aucun résultat trouvé</Typography>
      </Box>
    )
  }

  return (
    <Box textAlign="center" p={3}>
      <Typography variant="body1">
        Veuillez sélectionner un centre et un mois pour afficher les données
      </Typography>
    </Box>
  )
}

export default EmptyMessage