import { Card, Typography } from '@mui/material'

import HippocastList from './HippocastList'

import DashboardIcon from '@mui/icons-material/Dashboard'

export default () => (
  <Card sx={{ mt: 4, p: 2, minHeight: '80vh', boxShadow: 'rgba(0, 0, 0, 0.08) 0px 4px 12px' }}>
    <Typography variant="h4" sx={{ borderBottom: '1px dotted #ccc', p: 1 }}>
      <DashboardIcon /> Hippocast
    </Typography>

    <HippocastList />
  </Card>
)