import { supabaseClient } from '../supabase'
import baseDataProvider from '../supabase/baseDataProvider'
import { getItemFromLocalStorage } from '../common/LocalStorage'
import { set, get } from 'idb-keyval'

const localCurrentYear = getItemFromLocalStorage('localCurrentYear')?.id || process.env.REACT_APP_CURRENT_YEAR_ID

const CACHE_KEY = 'leadsDataCache'
const CACHE_TIMESTAMP_KEY = 'leadsDataCacheTimestamp'

// Function to save data in IndexedDB with timestamp
const setCachedData = async (data) => {
  try {
    await set(CACHE_KEY, data)
    const timestamp = Date.now() // Store current timestamp
    await set(CACHE_TIMESTAMP_KEY, timestamp)
  } catch (error) {
    console.error("Failed to save to IndexedDB", error)
  }
}

// Function to get cached data and check expiration
const getCachedData = async (cacheExpiration) => {
  try {
    const cachedData = await get(CACHE_KEY)
    const timestamp = await get(CACHE_TIMESTAMP_KEY)

    if (cachedData && timestamp) {
      const currentTime = Date.now()
      if (currentTime - timestamp < cacheExpiration) {
        return cachedData // Cache is still valid
      } else {
        return null // Cache expired, refetch needed
      }
    }
    return null // No cache found
  } catch (error) {
    console.error('Failed to retrieve from IndexedDB:', error)
    return null
  }
}

function replaceSpacesWithUnderscores(obj) {
  if (typeof obj !== 'object') return obj

  const result = {}

  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      if (key === 'phone@like') {
        const adjustPhoneNumber = obj[key].replace(/\s/g, '')
        result[key] = adjustPhoneNumber
        continue
      }

      result[key] = obj[key].replace(/\s|,/g, '_')
    } else {
      result[key] = obj[key]
    }
  }
  
  return result
}

// Filtering logic
const filterData = (data, filters) => {
  if (!filters || Object.keys(filters).length === 0) return data

  const cleanFilters = replaceSpacesWithUnderscores(filters)
  
  let filteredData = data

  // Check if group filters are applied
  if (filters.groupFilters === "ok") {
    // fetch group filters from local storage
    const groupedFilteredValues = getItemFromLocalStorage('groupedFilteredValues') ?? []
    const areFiltersPaused = getItemFromLocalStorage('pauseFilters') ?? false
    const betweenGroupsCondition = getItemFromLocalStorage('betweenGroupsCondition') ?? 'or'

    // console.log('groupedFilteredValues', groupedFilteredValues);

    filteredData = applyGroupFilters(filteredData, groupedFilteredValues, betweenGroupsCondition, areFiltersPaused)
  }

  Object.keys(cleanFilters).forEach((key) => {
    const filterValue = filters[key]
  
    if ( key === 'groupFilters') return
  
    if (key.endsWith('@ilike')) {
      const fieldKey = key.replace('@ilike', '')
      filteredData = filteredData?.filter(item =>
        item[fieldKey]?.toLowerCase().includes(filterValue.toLowerCase())
      )
    } else if (key === 'phone@like') {
      filteredData = filteredData?.filter(item =>
        item?.phone?.includes(filterValue)
      )
    } else if (key === 'center_id') {
      if (filterValue === '9999') {
        filteredData = filteredData?.filter(item => item?.center_id === null)
      } else {
        filteredData = filteredData?.filter(item => item?.center_id === filterValue)
      }
    } else if (key === 'favourited_by' || key === 'check_all_favourites') {
      if (key === 'check_all_favourites') {
        // Handle check_all_favourites: filter where favourited_by has at least one item with is_fav true
        filteredData = filteredData?.filter(item => 
          item?.favourited_by && item.favourited_by.some(fav => fav.is_fav !== null)
        )
      } else if (key === 'favourited_by') {
        // Handle favourited_by: check if it contains the filterId and is_fav is true
        const filterDetails = filters[key]["1"] // Access the nested structure
        const filterId = filterDetails?.filterId?.replace(/[{}]/g, '') // Clean up the filterId format
      
        if (filterId) {
          filteredData = filteredData?.filter(item =>
            item?.favourited_by?.some(fav => fav.id === filterId && fav.is_fav === true)
          )
        }
      }
    } else if (key === 'id' || key === 'assigned_pubeur') {
      filteredData = filteredData?.filter(item => item[key] === filterValue)
    } else if (key === 'stop_rgpd') {
      filteredData = filteredData?.filter(item => item?.stop_rgpd === false)
    }
  })  

  return filteredData
}

// Group filters
const applyGroupFilters = (data, groupedFilteredValues, betweenGroupsCondition, areFiltersPaused = false) => {
  if (!Array.isArray(groupedFilteredValues) || areFiltersPaused) return data // If filters are paused or groupedFilteredValues is not an array, return the original data

  let allGroupFilteredData = [] // To store the results of each group

  // Iterate over each group of filters
  groupedFilteredValues.forEach(group => {
    const groupCondition = group.groupCondition.toLowerCase() // 'and' or 'or'
    const groupFilters = group.groupFilters
    let groupFilteredData = data

    // Extract event and qualification filters if present in the group filters
    const eventFilterKeys = Object.keys(groupFilters).filter(filterName => filterName.startsWith('event_ids_'))
    const qualificationFilterKeys = Object.keys(groupFilters).filter(filterName => filterName.startsWith('events_qualifications_'))

    const eventFilter = eventFilterKeys.length > 0 ? groupFilters[eventFilterKeys[0]] : null
    const qualificationFilter = qualificationFilterKeys.length > 0 ? groupFilters[qualificationFilterKeys[0]] : null
    const hasEventFilter = !!eventFilter // Boolean indicating if an event filter exists
  
    // Iterate over each filter in the group and apply filters
    Object.keys(groupFilters).forEach(filterName => {
      const filterOptions = groupFilters[filterName] // Get the filter options
      const key = filterName.replace('_' + group.groupUniqueID, '')
  
      if ( isNotBoolFilterKey(key) ) {
        // Handle non-boolean filters (e.g., class_id, tags_ids)
        const filterResults = handleNonBoolFilters(groupFilteredData, key, filterOptions, groupCondition, hasEventFilter, eventFilter, qualificationFilter)

        if (groupCondition === 'and') {
          groupFilteredData = groupFilteredData?.filter(item => filterResults.includes(item))
        } else {
          groupFilteredData = [...new Set([...groupFilteredData, ...filterResults])]
        }
      } else {
        // Handle boolean filters (e.g., interested)
        const filterResults = handleBoolFilters(groupFilteredData, key, filterOptions, groupCondition)
        if (groupCondition === 'and') {
          groupFilteredData = groupFilteredData?.filter(item => filterResults.includes(item))
        } else {
          groupFilteredData = [...new Set([...groupFilteredData, ...filterResults])]
        }
      }
    })
  
    // Now that we've filtered within the group, store the result in allGroupFilteredData
    allGroupFilteredData.push(groupFilteredData)
  })  

  // Combine group results using the betweenGroupsCondition
  if ( betweenGroupsCondition === 'and' ) {
    // Intersection across all groups
    return allGroupFilteredData?.reduce((acc, curr) => acc.filter(item => curr.includes(item)), allGroupFilteredData[0])
  } else {
    // Union across all groups (deduplicated)
    return [...new Set(allGroupFilteredData?.flat())]
  }
}

// Helper function to handle non-boolean filters
const handleNonBoolFilters = (data, key, filterOptions, groupCondition = 'and', hasEventFilter = false, eventFilter = null, qualificationFilter = null) => {
  const shouldUseEvery = groupCondition.toLowerCase() === 'and'

  return data.filter(row => {
    const filteringMethod = shouldUseEvery ? 'every' : 'some'

    return Object.keys(filterOptions)[filteringMethod](filter => {
      let filterId = filterOptions[filter].filterId
      const filterMethod = filterOptions[filter].filterMethod

      // Adjust key for qualifications filtering if there is an event filter
      if (key === 'events_qualifications' && hasEventFilter && eventFilter) {
        key = 'qualifications_for_events'
      }

      // Get the correct rowField after potential key adjustment
      let rowField = row[key]

      // Only proceed if key is 'qualifications_for_events' and there is a corresponding event filter
      if (key === 'qualifications_for_events' && rowField && typeof rowField === 'object') {
        const eventFilterKey = Object.keys(eventFilter)[0]  // Access the first key in eventFilter (e.g., "2")
        const eventFilterId = eventFilter[eventFilterKey]?.filterId  // Get the filterId

        if (!eventFilterId) {
          // If the eventFilterId is not properly defined, skip further checks
          return false
        }

        // Ensure that eventFilterId matches an event in rowField (qualifications_for_events)
        if (eventFilterId in rowField) {
          const qualifications = rowField[eventFilterId]
          if (Array.isArray(qualifications) && qualifications.includes(filterId)) {
            // If qualification matches, return filterMethod (true for inclusion, false for exclusion)
            return filterMethod
          }
        }

        // If no matching event or qualification is found, return the opposite of filterMethod
        return !filterMethod
      }

      // Custom logic for null values
      if (filterId === '9999') {
        return filterMethod ? rowField === null : rowField !== null
      }

      // Handle array field filtering (e.g., tasks_info or others that might be arrays)
      if (Array.isArray(rowField)) {
        if (key === 'tasks_info') {
          for (const task of rowField) {
            if (
              (filterId === 0 && (task.progress === 0 || task.progress === null)) ||
              (filterId === 33 && (task.progress > 0 && task.progress < 100)) ||
              (filterId === 45 && (task.progress > 0 && task.is_waiting)) ||
              (filterId === 55 && (task.progress > 0 && !task.is_waiting)) ||
              (filterId === 100 && task.progress === 100) ||
              task.id === filterId
            ) {
              return filterMethod
            }
          }
        }

        // Check if the array contains the filterId for other array fields
        return filterMethod
          ? rowField.includes(filterId)
          : !rowField.includes(filterId)
      }

      // Handle equality and "contains" logic for non-array rowFields (like ids, names, etc)
      return filterMethod
        ? rowField === filterId
        : rowField !== filterId
    })
  })
}


// Helper function to handle boolean filters
const handleBoolFilters = (data, key, filterOptions) => {
  const filterMethod = filterOptions[1].filterMethod // Example: true/false

  return data.filter(item => {
    return item[key] === filterMethod
  })
}

const isNotBoolFilterKey = (key) => {
  return key !== 'interested'
    && key !== 'not_interested'
    && key !== 'registered'
}

const leadsDataProvider = {
  ...baseDataProvider,

  getList: async (resource, params, options = {}) => {
    const { filter: filters, pagination, sort } = params
    const { page, perPage } = pagination
    const { forceRefetch = false } = options // Allow refetching manually
  
    const nbrOfFilters = Object.keys(filters || {}).length // Count number of filters applied
  
    let cacheExpiration = 3000 // 3 sec
    if (nbrOfFilters > 0) {
      cacheExpiration = 180000 // 3 min
    }
  
    let cachedData = null
    if (!forceRefetch) {
      cachedData = await getCachedData(cacheExpiration) // Check if cached data exists
    }
  
    // Helper function to apply sorting
    const applySorting = (data) => {
      if (sort && sort.field && sort.order) {
        const { field, order } = sort
        return data.sort((a, b) => {
          const valueA = a[field] !== undefined && a[field] !== null && a[field] !== '' ? a[field] : null
          const valueB = b[field] !== undefined && b[field] !== null && b[field] !== '' ? b[field] : null
  
          // Handle sorting for null/empty values
          if (valueA === null && valueB === null) return 0
          if (valueA === null) return order === 'ASC' ? 1 : -1
          if (valueB === null) return order === 'ASC' ? -1 : 1
  
          // Use localeCompare for strings
          if (typeof valueA === 'string' && typeof valueB === 'string') {
            return order === 'ASC'
              ? valueA.localeCompare(valueB, 'en', { sensitivity: 'base' }) // Add locale and options
              : valueB.localeCompare(valueA, 'en', { sensitivity: 'base' })
          }
  
          // Default numeric/date comparison
          return order === 'ASC'
            ? valueA > valueB ? 1 : valueA < valueB ? -1 : 0
            : valueA < valueB ? 1 : valueA > valueB ? -1 : 0
        })
      }

      return data // If no sorting is applied, return the data as-is
    }

    // Helper function to apply filters if they are active
    const applyFilters = (data, filters) => nbrOfFilters > 0
      ? filterData(data, filters)
      : data

    // If cached data exists and filters are applied, filter the cached data
    if (cachedData && !forceRefetch) {
      let dataToReturn = cachedData
      
      // Apply filters if necessary
      dataToReturn = applyFilters(dataToReturn, filters)
  
      // Apply sorting
      dataToReturn = applySorting(dataToReturn)
  
      // Apply pagination
      const startIndex = (page - 1) * perPage
      const endIndex = startIndex + perPage
  
      return {
        data: dataToReturn.slice(startIndex, endIndex),
        total: dataToReturn.length,
      }
    }
  
    // get right resource based on current year
    if (localCurrentYear === process.env.REACT_APP_CURRENT_YEAR_ID) {
      resource = 'leads_view'
    } else if (localCurrentYear === 'e24834f3-d19a-4ad5-ad5d-85da26458f65') {
      resource = 'leads_view_2023'
    } else if (localCurrentYear === 'c46545ce-47f8-40d2-95b4-927cb6d73c01') {
      resource = 'leads_view_2022'
    }
  
    // Fetch data from Supabase if no valid cache exists or force refetch is true
    const { data, error } = await supabaseClient
      .from(resource)
      .select('*', { count: 'exact' })
      .order('updated_at', { ascending: false })
  
    if (error) {
      throw new Error(error.message)
    }
  
    // Cache the fetched data
    await setCachedData(data)
  
    // Apply sorting to fetched data
    let sortedData = applySorting(data)
  
    // Apply pagination after sorting
    const startIndex = (page - 1) * perPage
    const endIndex = startIndex + perPage
  
    return {
      data: sortedData.slice(startIndex, endIndex),
      total: sortedData.length,
    }
  },

  // Helper function to manually refetch data
  refetchData: async (resource, params) => {
    // Call getList with forceRefetch set to true
    return leadsDataProvider.getList(resource, params, { forceRefetch: true })
  },
}

export const dataProvider = leadsDataProvider