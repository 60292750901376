import * as Sentry from '@sentry/react'
import { createClient } from '@supabase/supabase-js'
import { supabaseIntegration } from '@supabase/sentry-js-integration'

const apiUrl = process.env.REACT_APP_SUPABASE_URL

export const supabaseClient = createClient(
  apiUrl,
  process.env.REACT_APP_SUPABASE_ANON_KEY
)

/*if ( process.env.NODE_ENV === 'production' ) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      supabaseIntegration(supabaseClient, Sentry, {
        tracing: true,
        breadcrumbs: true,
        errors: true,
      }),
      Sentry.browserTracingIntegration({
        shouldCreateSpanForRequest: (url) => {
          return !url.startsWith(`${apiUrl}`)
        },
      }),
    ],
    // allowUrls: [/https?:\/\/((cdn|www)\.)?example\.com/],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // Learn more at
    // https://docs.sentry.io/platforms/javascript/configuration/options/#traces-sample-rate
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: [
      /^\//, // For same-origin requests
      new RegExp(`^${apiUrl}`), // Dynamically add API URL
    ],
  })
}*/
