import * as React from 'react'
import { Card, Typography, Stack, useMediaQuery } from '@mui/material'

import DashboardPubeurLeads from './DashboardPubeurLeads'
import DashboardPubeurTasks from './DashboardPubeurTasks'
import DashboardPubeurEvents from './DashboardPubeurEvents'
import DashboardPubeurFrais from './DashboardPubeurFrais'

import DashboardIcon from '@mui/icons-material/Dashboard'

export default () => {
  const isMobile = useMediaQuery(
    theme => theme.breakpoints.down('md'),
    { noSsr: true }
  )

  return (
    <Card sx={{ mt: 4, p: 2, boxShadow: 'rgba(0, 0, 0, 0.08) 0px 4px 12px' }}>
      <Typography variant="h4" sx={{ borderBottom: '1px dotted #ccc', p: 1 }}>
        <DashboardIcon /> Mon tableau de bord
      </Typography>

      <Stack spacing={2} mt={4} direction={ isMobile ? 'column' : 'row' } justifyContent="flex-left">
        <DashboardPubeurLeads isMobile={isMobile} />
        <DashboardPubeurTasks isMobile={isMobile} />
        <DashboardPubeurEvents isMobile={isMobile} />
        <DashboardPubeurFrais isMobile={isMobile} />
      </Stack>
    </Card>
  )
}