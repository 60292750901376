import { useEffect, useState } from 'react'
import { useCreate, SimpleForm, TextInput, useNotify, SaveButton } from 'react-admin'
import { Box, Stack, Typography } from '@mui/material'

import { supabaseClient } from '../../supabase'
import { CardWithIcon } from '../../Design/Cards'

import EuroIcon from '@mui/icons-material/Euro'
import SmsIcon from '@mui/icons-material/Sms'

const SettingSmic = ({ value }) => {
  const notify = useNotify()
  const [create, { isPending, error }] = useCreate()
  const [smicHoraire, setSmicHoraire] = useState(null)
  const [smsAmount, setSMSamount] = useState(null)

  const getSmicAmount = async () => {
    const { data, error } = await supabaseClient
      .from('current_smic')
      .select('amount')
      .order('created_at', { ascending: false })
      .limit(1)

    if (error) {
      console.error('Error fetching event pubeurs:', error.message)
      return
    }

    setSmicHoraire(data[0])
  }

  const getSMSamount = async () => {
    const { data, error } = await supabaseClient
      .from('sms_price')
      .select('amount')
      .order('created_at', { ascending: false })
      .limit(1)

    if (error) {
      console.error('Error fetching event pubeurs:', error.message)
      return
    }

    setSMSamount(data[0])
  }

  const handleSubmitSMIC = (value) => {
    create('current_smic', {
      data: { amount: value?.amount }
    })

    if (error) {
      console.error('Error create new current SMIC', error)
      notify('Une erreur est survenue. Le nouveau montant du SMIC n\'a pas pu être mis à jour.', { type: 'error' })
      return
    }

    getSmicAmount()

    notify('Montant du SMIC horaire mis à jour', { type: 'success' })
  }

  const handleSubmitSMS = (value) => {
    const amount = value?.amount.replace(',', '.')

    create('sms_price', {
      data: { amount }
    })

    if (error) {
      console.error('Error create new current SMIC', error)
      notify('Une erreur est survenue. Le nouveau montant à payer par SMS n\'a pas pu être mis à jour.', { type: 'error' })
      return
    }

    getSMSamount()

    notify('Montant payé par SMS mis à jour', { type: 'success' })
  }

  useEffect(() => {
    getSmicAmount()
    getSMSamount()
  }, [])
  
  return (
    <Stack spacing={2} mt={2} direction="row">
      <Stack spacing={2} mt={2} direction="column" sx={{ width: '49%' }}>
        {/* Amount for SMIC/h */}
        <CardWithIcon
          to="/leads"
          icon={EuroIcon}
          title="Montant du SMIC horaire"
          subtitle={value}
          color="#ffbe4f"
        >
          <Box p={4}>
            <Typography variant="h4" gutterBottom>
              {smicHoraire?.amount} €
            </Typography>

            <Typography variant="body1" gutterBottom>
              En mettant à jour le montant du SMIC horaire, celui-ci sera automatiquement appliqué aux <i>futures</i> lignes de paie.
            </Typography>

            <SimpleForm
              onSubmit={handleSubmitSMIC}
              defaultValues={{ amount: '' }}
              toolbar={false}
            >
              <TextInput source="amount" label="Nouveau montant horaire BRUT" variant="outlined" />
              <SaveButton />
            </SimpleForm>
          </Box>
        </CardWithIcon>
      </Stack>

      <Stack spacing={2} mt={2} direction="column" sx={{ width: '49%' }}>
        {/* Amount for paid SMS */}
        <CardWithIcon
          to="/leads"
          icon={SmsIcon}
          title="Montant payé pour un SMS"
          subtitle={value}
          color="#ffbe4f"
        >
          <Box p={4}>
            <Typography variant="h4" gutterBottom>
              {smsAmount?.amount} €
            </Typography>

            <Typography variant="body1" gutterBottom>
              En mettant à jour le montant payé pour un SMS, celui-ci sera automatiquement appliqué aux <i>futures</i> lignes de paie.
            </Typography>

            <SimpleForm
              onSubmit={handleSubmitSMS}
              defaultValues={{ amount: '' }}
              toolbar={false}
            >
              <TextInput source="amount" label="Nouveau montant payé par SMS" variant="outlined" />
              <SaveButton />
            </SimpleForm>
          </Box>
        </CardWithIcon>
      </Stack>
    </Stack>
  )
}

export default SettingSmic