import { useEffect, useState, useMemo, useCallback } from 'react'
import { useGetIdentity, useNotify, useRedirect } from 'react-admin'
import { ContainerLayout } from '@react-admin/ra-navigation'
import { TourProvider } from '@react-admin/ra-tour'
import { Stack, useMediaQuery } from '@mui/material'
import { isEqual } from 'lodash'

import Tours from './Tours'
import { getRole } from './common/roles'
import { supabaseClient } from './supabase'
import LoadingPage from './Design/LoadingPage'
import CustomBreadCrumb from './CustomBreadCrumb'
import dataProvider from './supabase/dataProvider'
import CustomUserMenu, { MyMenu } from './UserMenu'
import FrontUpdateAvailable from './Design/FrontUpdateAvailable'
import { useServiceWorker } from './contexts/ServiceWorkerContext'
import { getItemFromLocalStorage, setItemToLocalStorage } from './common/LocalStorage'
import fetchEvents from './common/fetchEvents'

const getLeadYears = async () => {
  const { data, error } = await supabaseClient
    .from('lead_years')
    .select('id, name')
    .order('created_at', { ascending: false })

  if ( error ) {
    console.error('Error getting lead years: ', error)
    return []
  }

  return data
}

export const MyLayout = ({ children, ...rest }) => {
  const { isUpdateAvailable } = useServiceWorker()
  
  const { identity } = useGetIdentity()
  const notify = useNotify()
  const redirect = useRedirect()
  const role = identity?.role?.grade
  const isPubeur = identity?.role?.grade < getRole('responsable')
  const [profileTours, setProfileTours] = useState(null)
  const [maintenanceStyle, setMaintenanceStyle] = useState({display: 'none'})
  const isMobile = useMediaQuery(
    theme => theme.breakpoints.down('md'),
    { noSsr: true }
  )

  const currentTimestamp = isPubeur ? new Date().toISOString() : null

  const arraysValidator = (data) => Array.isArray(data)

  const defaultCurrentYearLocal = getItemFromLocalStorage('localCurrentYear')
  const cachedLeadYears = getItemFromLocalStorage('leadYears', null, arraysValidator)
  const localCenter = getItemFromLocalStorage('localCenter')
  const localUserGrade = getItemFromLocalStorage('localUserGrade')
  const localUserId = getItemFromLocalStorage('localUserId')
  const maintenanceStatusCheck = getItemFromLocalStorage('maintenanceStatusCheck')
  const centerTags = getItemFromLocalStorage('centerTags')
  const eventsInfo = getItemFromLocalStorage('eventsInfo')

  const profileToursStatus = getItemFromLocalStorage('profileToursStatus')

  const fetchData = useCallback(async () => {
    if (!identity?.id) return
  
    try {
      const promises = []
  
      if (!defaultCurrentYearLocal) {
        promises.push(
          dataProvider.getOne('lead_years', { id: process.env.REACT_APP_CURRENT_YEAR_ID })
        )
      }
  
      if (!cachedLeadYears) {
        promises.push(getLeadYears())
      }
  
      if (!localCenter) {
        promises.push(Promise.resolve(identity?.center))
      }
  
      if (!localUserGrade) {
        promises.push(Promise.resolve(role))
      }
  
      if (!localUserId) {
        promises.push(Promise.resolve(identity?.id))
      }
  
      const results = await Promise.all(promises)
  
      if (!defaultCurrentYearLocal && results[0]?.data) {
        setItemToLocalStorage('localCurrentYear', results[0].data)
      }
  
      if (!cachedLeadYears) {
        setItemToLocalStorage('leadYears', results[1])
      }
  
      if (!localCenter) {
        setItemToLocalStorage('localCenter', results[2])
      }
  
      if (!localUserGrade) {
        setItemToLocalStorage('localUserGrade', results[3])
      }
  
      if (!localUserId) {
        setItemToLocalStorage('localUserId', results[4])
      }
    } catch (error) {
      console.error('Error setting up local data for the current user', error)
    }
  }, [identity, dataProvider, cachedLeadYears, defaultCurrentYearLocal, localCenter, localUserGrade, localUserId, role])
  
  useEffect(() => {
    fetchData()
  }, [fetchData])
  
  useEffect(() => {
    const getData = async () => {
      const promises = []
  
      promises.push(
        supabaseClient
          .from('isUnderMaintenance')
          .select('status')
          .single()
      )
  
      if (!centerTags || Date.now() - centerTags.updatedAt > 600000) {
        promises.push(
          supabaseClient
            .from('tags')
            .select('*')
            .eq('center_id', identity?.center?.id)
        )
      } else {
        promises.push(Promise.resolve(null))
      }
  
      if (!eventsInfo || Date.now() - eventsInfo.updatedAt > 120000) {
        promises.push(fetchEvents(identity?.center?.id, identity, currentTimestamp))
      } else {
        promises.push(Promise.resolve(null))
      }
  
      const [maintenanceResult, tagsResult, eventsResult] = await Promise.all(promises)
  
      if (maintenanceResult?.data) {
        setMaintenanceStyle({
          display: maintenanceResult.data.status ? 'flex' : 'none',
        })
        setItemToLocalStorage('maintenanceStatusCheck', Date.now())
      }
  
      if (tagsResult?.data) {
        setItemToLocalStorage('centerTags', { tags: tagsResult.data, updatedAt: Date.now() })
      }
  
      if (eventsResult) {
        const { data: qualifications } = await supabaseClient
          .from('types_qualifications')
          .select('id,name')
  
        for (const event of eventsResult) {
          if (event.date) {
            const [datePart] = event.date.split('T')
            event.startDate = datePart || null
          }
  
          if (event.startDate) {
            const [year, month, day] = event.startDate.split('-')
            event.startDate = `${day}/${month}/${year}`
          }
  
          if (event.qualifications_ids) {
            event.qualificationInfo = event.qualifications_ids.map(id =>
              qualifications.find(q => q.id === id)
            )
          }
        }
  
        eventsResult.sort((a, b) => new Date(b.startDate) - new Date(a.startDate))
        setItemToLocalStorage('eventsInfo', { events: eventsResult, updatedAt: Date.now() })
      }
    }
  
    if (
      identity &&
      (!maintenanceStatusCheck || maintenanceStatusCheck < Date.now() - 3600000)
    ) {
      getData()
    }
  }, [identity, maintenanceStatusCheck, centerTags, eventsInfo, currentTimestamp])
  
  const memoizedStyle = useMemo (
    () => ({
      ...maintenanceStyle,
      backgroundColor: '#f79525',
      padding: '10px',
      fontSize: '.9rem',
      fontWeight: 600,
    }),
    [maintenanceStyle] // Only recalculate if `maintenanceStyle` changes
  )
  
  useEffect(() => {
    if (!identity || !profileTours) return
  
    const updateProfile = async () => {
      console.log('Init. Updating profile tours...')
  
      // Update localStorage for tracking
      setItemToLocalStorage('profileToursStatus', profileTours)
  
      // Update tours status in the database
      const { error } = await supabaseClient
        .from('profiles')
        .update({ tours: profileTours })
        .eq('id', identity.id)
        .single()
  
      if (error) {
        console.error('Error updating profile: ', error)
      }
    }
  
    // Check if the tours have changed (e.g., new tours added)
    if (!profileToursStatus || !isEqual(profileToursStatus, profileTours)) {
      updateProfile()
    }
  }, [identity, profileTours, profileToursStatus])

  // useEffect(() => {
  //   (async () => {
  //     const { data, error } = await supabaseClient.auth.getSession()
    
  //     if (error || !data.session) {
  //       console.error('Failed to retrieve session:', error?.message)
  //       return null
  //     }
    
  //     const token = data.session.access_token
      
  //     localStorage.setItem('userToken', token);
  //   })()
  // }, [])

  return identity ? (
    <>
      { isUpdateAvailable &&
        <FrontUpdateAvailable />
      }

      <TourProvider tours={Tours} tools={{ notify, redirect, profileTours, setProfileTours }}>
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" sx={memoizedStyle}>
          Une opération de maintenance est en cours. Merci de votre compréhension.
        </Stack>

        <ContainerLayout
          {...rest}
          title={ isMobile ? '' : ! isPubeur ? '' : 'Forma Contacts'}
          maxWidth="xl"
          menu={<MyMenu role={role} {...rest} />}
          userMenu={<CustomUserMenu />}
          sx={ role === getRole('admin') ? {
            '& .RaHeader-toolbar .MuiTab-root': {
              minWidth: '50px',
              typography: { fontSize: '.6rem', fontWeight: 300, padding: '0px 5px' } },
            } : role >= getRole('responsable') ? {
              '& .RaHeader-toolbar .MuiTab-root': {
                minWidth: '55px',
                typography: { fontSize: '.6rem', fontWeight: 300, padding: '0px 5px' } },
            } : {
              '& .RaHeader-toolbar .MuiTab-root': {
                minWidth: '70px',
                typography: { fontSize: '.8rem', fontWeight: 500, padding: '0px 15px' } },
            }
          }
        >
          <CustomBreadCrumb />
          {children}
        </ContainerLayout>
      </TourProvider>
    </>
  ) : <LoadingPage />
}
