import { getItemFromLocalStorage, setItemToLocalStorage } from '../common/LocalStorage'
import authProvider from './authProvider'

import { clear } from 'idb-keyval'

const customAuthProvider = (roleGrade) => {
  const getIdentity = async (profile) => {
    const identity = await authProvider.getIdentity(profile)

    let setRoleGrade
    if ( roleGrade !== null && getItemFromLocalStorage('localUserGrade') && roleGrade !== getItemFromLocalStorage('localUserGrade') ) {
      setRoleGrade = roleGrade
    } else {
      setRoleGrade = getItemFromLocalStorage('localUserGrade')
    }

    return {
      ...identity,
      role: {
        ...identity.role,
        grade: setRoleGrade ?? identity.role.grade,
      },
    }
  }

  return {
    ...authProvider,
    getIdentity,
    logout: async () => {
      localStorage.removeItem('localCurrentYear')
      localStorage.removeItem('localCenter')
      localStorage.removeItem('localUserGrade')
      localStorage.removeItem('localUserId')
      localStorage.removeItem('groupedFilteredValues')
      localStorage.removeItem('filterValuesBeforeGrouped')
      localStorage.removeItem('pauseFilters')
      localStorage.removeItem('centerTags')
      localStorage.removeItem('maintenanceStatusCheck')
      localStorage.removeItem('leadYears')
      localStorage.removeItem('centersInfo')
      localStorage.removeItem('notificationsCheck')
      localStorage.removeItem('profileToursStatus')
      localStorage.removeItem('eventsInfo')
      localStorage.removeItem('userToken')

      // Clear IndexedDB cache
      await clear()
   
      return authProvider.logout()
    }
  }
}

export default customAuthProvider
