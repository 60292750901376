// MonthFilter.js
import { useListContext } from 'react-admin'
import { useState, useEffect } from 'react'
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material'

const formatDate = (date) => {
  return date.toISOString().split('T')[0]
}

const getMonthOptions = () => {
  const options = []
  const frenchMonths = [
    'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
    'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
  ]
  
  const startDate = new Date(2024, 9) // October 2024
  const currentDate = new Date()
  
  let iterDate = new Date(startDate)
  
  // Stop iterating before the current month
  while (iterDate < new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)) {
    const year = iterDate.getFullYear()
    const month = iterDate.getMonth()
    const value = `${year}-${month + 1}`.padStart(7, '0') // Ensure format like YYYY-MM
    const label = `${frenchMonths[month]} ${year}`
    
    options.unshift({ value, label })
    iterDate.setMonth(iterDate.getMonth() + 1)
  }
  
  return options
}

const MonthFilter = () => {
  const { filterValues, setFilters } = useListContext()
  const [selectedMonth, setSelectedMonth] = useState('')
  const monthOptions = getMonthOptions()

  useEffect(() => {
    if (selectedMonth) {
      const [year, month] = selectedMonth.split('-')
      const startDate = new Date(parseInt(year), parseInt(month) - 1, 1)
      const endDate = new Date(parseInt(year), parseInt(month), 0)
      
      setFilters({
        ...filterValues,
        '@and': {
          'created_at@gte': formatDate(startDate),
          'created_at@lte': formatDate(endDate)
        }
      })
    } else {
      const { '@and': _, ...remainingFilters } = filterValues
      setFilters(remainingFilters)
    }
  }, [selectedMonth, setFilters, filterValues])

  return (
    <FormControl sx={{ minWidth: 200, mr: 2 }} size="small">
      <InputLabel id="month-filter-label">Filtrer par mois d'import</InputLabel>
      <Select
        labelId="month-filter-label"
        id="month-filter"
        value={selectedMonth}
        onChange={(e) => setSelectedMonth(e.target.value)}
        label="Filtrer par mois"
      >
        <MenuItem value="">Tous les mois</MenuItem>
        {monthOptions.map(({ value, label }) => (
          <MenuItem key={value} value={value}>{label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default MonthFilter