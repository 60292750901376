import { useState, useEffect } from 'react'
import {
  ArrayInput,
  List,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  useGetIdentity,
  useRecordContext,
  AutocompleteInput,
  DatagridConfigurable,
  IconButtonWithTooltip,
} from 'react-admin'
import { FormControl, Select as MUIselect, InputLabel, MenuItem, Box, Typography } from '@mui/material'

import { getRole } from '../../common/roles'
import { supabaseClient } from '../../supabase'
import { TextFieldCopy } from '../../Design/CustomTextField'
import { FormCard, PaieCardWithIcon } from '../../Design/Cards'

import RefreshIcon from '@mui/icons-material/Refresh'
import LinearProgress from '@mui/material/LinearProgress'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import DashboardToolbar from './DashboardToolbar'

const ExpandPubeur = ({ selectedMonth }) => {
  const record = useRecordContext()
  const [paiePubeurs, setPaiePubeurs] = useState(null)
  const [totalPubeur, setTotalPubeur] = useState(0)

  const fetchPaiePubeurs = async () => {
    if (!record || !selectedMonth) return

    try {
      const startOfMonth = parseMonthString(selectedMonth)
      const endOfMonth = new Date(startOfMonth)
      endOfMonth.setMonth(endOfMonth.getMonth() + 1)

      console.log('startOfMonth', startOfMonth)
      console.log('endOfMonth', endOfMonth)

      const { data, error } = await supabaseClient
        .from('paie_pubeur')
        .select('*')
        .eq('profile_id', record.id)
        .gte('created_at', startOfMonth.toISOString())
        .lt('created_at', endOfMonth.toISOString())

      if (error) {
        console.error('Error fetching data:', error)
        return
      }

      setPaiePubeurs(data)

      // Calculate total paid
      const total = data.reduce((acc, pubeur) => acc + (pubeur.amount * pubeur.quantity), 0).toFixed(2)
      setTotalPubeur(total)
    } catch (err) {
      console.error('Error parsing month:', err)
    }
  }

  // Fetch paie_pubeurs for the current pubeur when selected month or record changes
  useEffect(() => {
    fetchPaiePubeurs()
  }, [record, selectedMonth])

  const handleSubmit = async (values) => {
    const { paie_pubeurs } = values

    const newRows = paie_pubeurs.filter(p => !p.id) // Rows without 'id' are new
    const updatedRows = paie_pubeurs.filter(p => p.id) // Rows with 'id' are updates

    const newValues = newRows.map(pubeur => ({
      ...pubeur,
      profile_id: record.id,
    }))

    const updateValues = updatedRows.map(pubeur => ({
      ...pubeur,
      profile_id: record.id,
    }))

    const deletedValues = paiePubeurs.filter(pubeur => !paie_pubeurs.find(p => p.id === pubeur.id))

    try {
      if (!record) return

      // Insert new rows
      if (newValues.length > 0) {
        const { error } = await supabaseClient
          .from('paie_pubeur')
          .insert(newValues)

        if (error) {
          console.error('Error inserting new rows:', error)
        }
      }

      // Update existing rows
      if (updateValues.length > 0) {
        const { error } = await supabaseClient
          .from('paie_pubeur')
          .upsert(updateValues, { onConflict: ['id'] })

        if (error) {
          console.error('Error updating rows:', error)
        }
      }

      // Delete rows
      if (deletedValues.length > 0) {
        const { error } = await supabaseClient
          .from('paie_pubeur')
          .delete()
          .in('id', deletedValues.map(p => p.id))

        if (error) {
          console.error('Error deleting rows:', error)
        }
      }

      // Refetch the data to update the UI
      fetchPaiePubeurs()
    } catch (err) {
      console.error('Error during submission:', err)
    }
  }

  return paiePubeurs ? (
    <FormCard>
      <SimpleForm onSubmit={handleSubmit} record={{ paie_pubeurs: paiePubeurs }}>
        <ArrayInput source="paie_pubeurs" label={false}>
          <SimpleFormIterator inline>
            <ReferenceInput source="mission_id" reference="paie_missions">
              <AutocompleteInput optionText="name" label="Mission" sx={{ width: '49%' }} />
            </ReferenceInput>
            <ReferenceInput source="unit_id" reference="paie_units">
              <AutocompleteInput optionText="name" label="Unité de mesure" sx={{ width: '20%' }} />
            </ReferenceInput>
            <NumberInput source="amount" label="Montant" sx={{ width: '13%' }} />
            <NumberInput source="quantity" label="Quantité" sx={{ width: '12%' }} />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>

      <Typography variant="body1" sx={{ fontWeight: 'bold', textAlign: 'right' }} gutterBottom>
        {`TOTAL BRUT : ${totalPubeur} €`}
        <IconButtonWithTooltip
          label="Rafraîchir"
          onClick={fetchPaiePubeurs}
        >
          <RefreshIcon />
        </IconButtonWithTooltip>
      </Typography>
    </FormCard>
  ) : <LinearProgress color="secondary" />
}

const monthNamesFR = {
  'janvier': 0,
  'février': 1,
  'mars': 2,
  'avril': 3,
  'mai': 4,
  'juin': 5,
  'juillet': 6,
  'août': 7,
  'septembre': 8,
  'octobre': 9,
  'novembre': 10,
  'décembre': 11
}

const parseMonthString = (monthString) => {
  const [monthName, year] = monthString.toLowerCase().split(' ')
  const month = monthNamesFR[monthName]

  if ( month === undefined || isNaN ( year ) ) {
    throw new Error('Invalid month format')
  }

  const currentDate = new Date()

  // Determine if today is between the 16th and the end of the current month
  if (currentDate.getDate() > 15) {
    // Subtract one month and set day to 16
    const adjustedMonth = new Date(Number(year), month - 1, 16) // -1 adjusts to the previous month
    return adjustedMonth
  }

  // Otherwise, set the day to the 16th of the current month
  return new Date(Number(year), month, 16)
}

const getPreviousMonths = (count) => {
  const months = []
  const currentDate = new Date()

  for (let i = 0; i < count; i++) {
    const monthDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1)
    months.push(monthDate.toLocaleString('fr', { month: 'long', year: 'numeric' }))
  }

  return months
}

const DashboardRespoPaies = () => {
  const { identity } = useGetIdentity()
  const isAdmin = identity?.role?.grade === getRole('admin')

  // Set the default selected month to the current month
  let currentMonth = new Date().toLocaleString('fr', { month: 'long', year: 'numeric' })
  if (new Date().getDate() > 15) {
    const nextMonth = new Date()
    nextMonth.setMonth(nextMonth.getMonth() + 1)
    currentMonth = nextMonth.toLocaleString('fr', { month: 'long', year: 'numeric' })
  }

  const [month, setMonth] = useState(currentMonth)

  const handleChangeMonth = (event) => {
    setMonth(event.target.value)
  }

  // Check if the selected month is the current month
  const isCurrentMonth = month === currentMonth

  // Filter pubeur without paie
  const paieField = isCurrentMonth ? 'has_paie_current_month' : 'has_paie_last_month'

  return (
    <Box sx={{ p: 0 }}>
      <PaieCardWithIcon
        icon={AccountBalanceIcon}
        title={month.charAt(0).toUpperCase() + month.slice(1)}
        subtitle={isCurrentMonth
          ? "Vous pouvez éditer les paies jusqu'au 14 du mois suivant à 23h59."
          : "Archive pour consultation uniquement"}
        colorTitle="#fbc410"
      >
        <div style={{ width: '30%' }}>
          <FormControl variant="outlined" sx={{ m: .5, minWidth: '100%' }}>
            <InputLabel id="monthLabel">Archives des mois précédents</InputLabel>
            <MUIselect
              labelId="monthLabel"
              id="select-monthLabel"
              value={month}
              variant="outlined"
              onChange={handleChangeMonth}
              label="Archives des mois précédents"
            >
              <MenuItem value={currentMonth}>Mois actuel</MenuItem>
              {getPreviousMonths(12).map((monthValue, index) => (
                <MenuItem key={index} value={monthValue}>{monthValue}</MenuItem>
              ))}
            </MUIselect>
          </FormControl>
        </div>
      </PaieCardWithIcon>

      <List
        resource="profiles"
        basePath="/profiles"
        perPage={20}
        filter={ isAdmin
          ? {
            enabled: true,
            'role_id@neq': ['597c594e-c095-4671-a2fa-bce91f2bf920', 'e7d5673e-9f94-4229-a1b4-bb1f4dcd71de'],
            [paieField]: true
            }
          : {
            enabled: true, center_id: identity?.center?.id,
            'role_id@neq': ['597c594e-c095-4671-a2fa-bce91f2bf920', 'e7d5673e-9f94-4229-a1b4-bb1f4dcd71de'],
            [paieField]: true
            }
        }
        exporter={false}
        actions={false}
        sx={{ mt: 2 }}
      >
        <DashboardToolbar isAdmin={isAdmin} />

        <DatagridConfigurable
          bulkActionButtons={false}
          rowClick="expand"
          expand={<ExpandPubeur selectedMonth={month} />}
          expandSingle
        >
          <TextField source="first_name" label="Prénom" />
          <TextField source="last_name" label="Nom" />
          <TextFieldCopy source="id" label="ID" />
          <ReferenceField source="role_id" reference="roles" label="Role" link={false}>
            <TextField source="name" />
          </ReferenceField>
        </DatagridConfigurable>
      </List>
    </Box>
  )
}

export default DashboardRespoPaies
