import { useEffect, useState } from 'react'
import { TextField, useGetIdentity, useNotify, useRecordContext, useRefresh, Button, useRedirect } from 'react-admin'
import { IconButton } from '@mui/material'

import { getRole } from '../common/roles'
import Tooltip from '@mui/material/Tooltip'
import { supabaseClient } from '../supabase'
import customColor from '../common/customColor'

import CircleIcon from '@mui/icons-material/Circle'
import CancelIcon from '@mui/icons-material/Cancel'
import PendingIcon from '@mui/icons-material/Pending'
import StarRateIcon from '@mui/icons-material/StarRate'
import StarOutlineIcon from '@mui/icons-material/StarOutline'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff'
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges'

const buttonStyle = {
  padding: '5px',
  borderRadius: '5px',
  minWidth: '100px',
}

export const TruncatedTextField = ({ source, maxLength = 25 }) => {
  const record = useRecordContext()
  const redirect = useRedirect()
  const {textColor, bgColor} = customColor(record)

  const style = {
    ...buttonStyle,
    color: textColor,
    backgroundColor: bgColor,
  }
  
  const text = record[source]
  const truncatedText = text?.length > maxLength ? `${text?.substring(0, maxLength)}...` : text ?? null

  const handleClickComment = (e) => {
    e.stopPropagation()
    redirect(`/leads/${record.id}/comments`)
  }

  return source === 'last_comment'
    ? <Tooltip title={text} placement="top-start" onClick={(e) => handleClickComment(e)}>
        <span style={style}>{ truncatedText }</span>
      </Tooltip>
    : record[source] ? <span style={style}>{ truncatedText }</span> : null
}

export const PubeurTextField = (props) => {
  const record = useRecordContext()
  const refresh = useRefresh()

  const { textColor, bgColor } = customColor(record)

  const style = {
    ...buttonStyle,
    color: record.momentary_assignation?.isMomentary
      ? '#fefefe'
      : bgColor !== '#e21f1f'
        ? '#111'
        : textColor,
    backgroundColor: record.momentary_assignation?.isMomentary
      ? '#1c94bc'
      : bgColor !== '#e21f1f'
        ? '#ffbd72'
        : bgColor,
    minWidth: '210px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between', // Space between grouped content and CancelIcon
    padding: '0.25rem',
  }

  const removemomentary_assignation = async (e) => {
    e.stopPropagation()

    const { error: errorUpdatingAssignationLog } = await supabaseClient
      .from('assignation_logs')
      .update({ duration: null })
      .eq('id', record.momentary_assignation.assignationLogId)

    if (errorUpdatingAssignationLog) {
      console.error('Error removing momentary assignation:', errorUpdatingAssignationLog)
      return
    }

    const { error: errorUpdatingLead } = await supabaseClient
      .from('leads')
      .update({ assigned_pubeur: record.momentary_assignation.originalPubeur })
      .eq('id', record.id)

    if (errorUpdatingLead) {
      console.error('Error removing momentary assignation:', errorUpdatingLead)
    }

    refresh()
  }

  return record.assigned_pubeur_name ? (
    <div style={style}>
      <div style={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}>
        {record.momentary_assignation?.isMomentary && (
          <HistoryToggleOffIcon style={{ fontSize: '1rem', color: '#fefefe' }} />
        )}
        
        <span>{record.assigned_pubeur_name}</span>
      </div>

      {record.momentary_assignation?.isMomentary && (
        <CancelIcon
          sx={{ fontSize: '1rem' }}
          onClick={(e) => removemomentary_assignation(e)}
        />
      )}
    </div>
  ) : null
}

export const TasksTextField = () => {
  const info = useRecordContext()
  const redirect = useRedirect()

  const tasks = info?.tasks_info

  if ( ! tasks ) return null

  let countNotStarted = 0
  let countInProgress = 0
  let countIsWaiting = 0
  let countIsReady = 0

  tasks.forEach(task => {
    if ( task?.progress === '0' || task?.progress === null ) {
      countNotStarted++
    } else if ( task?.is_waiting !== null && task?.callback ) {
      const isWaiting = task?.is_waiting

      if ( isWaiting ) {
        countIsWaiting++
      } else {
        countInProgress++
      }
    } else if ( task?.progress > '0' && task?.is_waiting !== true && task?.progress !== '100' ) {
      countInProgress++
    } 
  })

  return (
    <>
      { tasks && tasks.length > 0 ? (
        <>
          {countNotStarted > 0 && (
            <Button
              label={`${countNotStarted}`}
              startIcon={<AssignmentTurnedInIcon />}
              onClick={(e) => {
                e.stopPropagation()
                redirect('/leads/' + info?.id + '/tasks')
              }}
            />
          )}

          {countInProgress > 0 && (
            <Button
              label={`${countInProgress}`}
              startIcon={<AssignmentTurnedInIcon color="warning" />}
              sx={{ color: 'darkred' }}
              onClick={(e) => {
                e.stopPropagation()
                redirect('/leads/' + info?.id + '/tasks')
              }}
            />
          )}
          
          {countIsWaiting > 0 && (
            <Button
              label={`${countIsWaiting}`}
              startIcon={<PendingIcon sx={{ color: '#FFCF9D' }} />}
              disabled
            />
          )}

          {countIsReady > 0 && (
            <Button
              label={`${countIsReady}`}
              sx={{ color: '#e58e32' }}
              startIcon={<PublishedWithChangesIcon color="warning" />}
              onClick={(e) => {
                e.stopPropagation()
                redirect('/leads/' + info?.id + '/tasks')
              }}
            />
          )}
        </>
      ) : null }
    </>
  )
}

export const SourceTextField = props => {
  const info = useRecordContext();

  if ( info.source_id ) {
    info.lead_source_id = info.source_id
  }

  let sourceIcon
  if ( info.lead_source_id === process.env.REACT_APP_LEAD_SOURCE_IMPORT_NOMAD ) {
    sourceIcon = <Tooltip title="NOMAD"><CircleIcon sx={{ color: '#efa87c', fontSize: '1rem' }} /></Tooltip>
  } else if ( info.lead_source_id === process.env.REACT_APP_LEAD_SOURCE_IMPORT_BROCHURES ) {
    sourceIcon = <Tooltip title="Demande de brochure"><CircleIcon sx={{ color: '#c29cd8', fontSize: '1rem' }} /></Tooltip>
  } else if ( info.lead_source_id === process.env.REACT_APP_LEAD_SOURCE_WEB ) {
    sourceIcon = <Tooltip title="Contact web"><CircleIcon sx={{ color: '#dfed72', fontSize: '1rem' }} /></Tooltip>
  } else if ( info.lead_source_id === process.env.REACT_APP_LEAD_SOURCE_MOBILE ) {
    sourceIcon = <Tooltip title="Contact mobile"><CircleIcon sx={{ color: '#19e519', fontSize: '1rem' }} /></Tooltip>
  } else if ( info.lead_source_id === process.env.REACT_APP_LEAD_SOURCE_CSV_IMPORT ) {
    sourceIcon = <Tooltip title="Import CSV"><CircleIcon sx={{ color: '#51edc7', fontSize: '1rem' }} /></Tooltip>
  } else if ( info.lead_source_id === process.env.REACT_APP_LEAD_SOURCE_IMPORT_DIPLOMEO ) {
    sourceIcon = <Tooltip title="Contact Diplomeo"><CircleIcon sx={{ color: '#344CBE', fontSize: '1rem' }} /></Tooltip>
  } else if ( info.lead_source_id === process.env.REACT_APP_LEAD_SOURCE_IMPORT_Q_PARCOURSUP ) {
    sourceIcon = <Tooltip title="Question Parcoursup"><CircleIcon sx={{ color: '#d861cc', fontSize: '1rem' }} /></Tooltip>
  } else if ( info.lead_source_id === process.env.REACT_APP_LEAD_SOURCE_IMPORT_DEMANDE_RAPPEL ) {
    sourceIcon = <Tooltip title="Demande de rappel"><CircleIcon sx={{ color: '#e33460', fontSize: '1rem' }} /></Tooltip>
  } else if ( info.lead_source_id === process.env.REACT_APP_LEAD_SOURCE_IMPORT_HIPPOCAST ) {
    sourceIcon = <Tooltip title="Import Hippocast"><CircleIcon sx={{ color: '#c5efac', fontSize: '1rem' }} /></Tooltip>
  } else if ( info.lead_source_id === process.env.REACT_APP_LEAD_SOURCE_IMPORT_THOTIS ) {
    sourceIcon = <Tooltip title="Import Thotis"><CircleIcon sx={{ color: '#d2aeb7', fontSize: '1rem' }} /></Tooltip>
  } else {
    sourceIcon = <Tooltip title="Origine inconnue"><CircleIcon sx={{ color: '#eee', fontSize: '1rem' }} /></Tooltip>
  }

  return sourceIcon
}

export const InfoTextField = record => {
  const info = useRecordContext()
  const {textColor, bgColor} = customColor(info)

  const style = {
    ...buttonStyle,
    color: textColor,
    backgroundColor: bgColor,
    width: '10%'
  }

  return info[record.source] ? <TextField source={record.source} sx={style} /> : null
}

export const YellowTextField = record => {
  const info = useRecordContext()
  const {textColor} = customColor(info)

  const style = {
    ...buttonStyle,
    color: textColor,
    backgroundColor: '#f9dfbb',
  }

  return info[record.source] ? <TextField source={record.source} style={style} /> : null
}

export const TextFieldCopy = ({ source, maxWidth, ...rest }) => {
  const record = useRecordContext()
  const notify = useNotify()
  
  const copyField = () => {
    if (record && record[source]) {
      navigator.clipboard.writeText(record[source])
      notify('ID copiée dans le presse-papier', { type: 'success' })
    }
  }

  return record ? (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <TextField source={source} style={{ flex: 1, maxWidth: maxWidth }} {...rest} />
      
      <IconButton
        size="small"
        onClick={e => {
          copyField()
          e.stopPropagation()
        }}
        sx={{ marginLeft: '5px' }}
      >
        <ContentCopyIcon />
      </IconButton>
    </div>
  ) : null
}

export const CenterTextField = record => {
  const info = useRecordContext()
  const [isLoading, setIsLoading] = useState(true)
  const [getCenter, setGetCenter] = useState(null)

  const fetchCenterName = async () => {
    try {
      const { data, error } = await supabaseClient
        .from('centers')
        .select('id, name')
        .eq('id', info?.center_ids[0])

      if ( ! data ) return <></>

      setGetCenter(data[0].name)
    } catch (error) {
      console.error('Error fetching lead center: ' + error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchCenterName()
  }, [])

  if (isLoading) return null

  return <>{getCenter}</>
}

export const FavouriteTextField = () => {
  const info = useRecordContext()
  const { identity } = useGetIdentity()
  const currentUser = identity?.id
  const isPubeur = identity?.role?.grade < getRole('responsable')

  if (!info?.favourited_by) return <StarOutlineIcon />

  if (info?.assigned_pubeur === null || info?.favourited_by?.length === 0) return null

  let userEntry = null
  if ( ! isPubeur ) {
    userEntry = info?.favourited_by?.find(entry => entry?.id === info?.assigned_pubeur)
  } else {
    userEntry = info?.favourited_by?.find(entry => entry?.id === currentUser)
  }

  if (!userEntry) return <StarOutlineIcon />

  return userEntry.is_fav ? <StarRateIcon /> : <StarOutlineIcon />
}